import React from "react"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import { up } from "styled-breakpoints"

import Layout from "../components/layout"
import Text from "../components/text"
import Img from "../components/img"
import Form from "../components/form"

import { space } from "../theme"

const Wrapper = styled.div`
  display: grid;
`

const Container = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  margin: 0 auto;
  margin-top: 90px;
  padding: 0 2rem;
  -webkit-font-smoothing: antialiased;
  ${up("tablet")} {
    grid-template-columns: 704px;
    padding: 0 2rem;
  }
  ${up("desktop")} {
    padding: 0;
    grid-template-columns: 960px;
  }
`

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: ${space("normal")};
  grid-row-gap: ${space("large")};
  padding: ${space("large")} 0;
  ${up("tablet")} {
    grid-template-columns: 1fr 1fr;
    padding-bottom: 0;
  }
`
const U = styled.ul`
  list-style-type: disc;
  padding-left: ${space("normal")};
`
const Left = styled.div`
  display: grid;
  grid-gap: ${space("small")};
`

const II = styled(Img)`
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
`

const Whitepaper = () => {
  return (
    <Layout minimal>
      <Helmet>
        <title>Paybase - Whitepaper</title>
        <meta property="og:title" content="Paybase - Whitepaper" />
      </Helmet>
      <Wrapper>
        <Container>
          <Text type="headline" align="center" weight="bold">
            Escrow - is the future brighter?
          </Text>
          <Text type="headline" align="center">
            How payments providers are revolutionising escrow
          </Text>
          <Content>
            <Left>
              <II
                src="/escrow_booklet.png"
                alt="white paper cover"
                mw="90%"
                mh="auto"
                tw={"auto"}
                th={"280px"}
                fit="fill"
              />
              <Text>
                Escrow is the act of holding funds in a third party account when
                a payment is made. Once the transacting parties are happy, the
                transaction can be authorised and the funds released.
              </Text>
              <Text>
                Escrow has the potential to significantly augment the growing
                platform economy, but up until now, it has neither been widely
                utilised or understood. In this Paybase whitepaper, we cover:
              </Text>
              <Text>
                <U>
                  <li>The ramifications of the digitisation of escrow</li>
                  <li>
                    Why the platform economy is the perfect space for the
                    instrument
                  </li>
                  <li>
                    How innovative payments providers are optimising escrow to
                    cater to the demands of modern consumers
                  </li>
                </U>
              </Text>
            </Left>
            <Form btnCopy="Download" type="whitepaper" />
          </Content>
        </Container>
      </Wrapper>
    </Layout>
  )
}

export default Whitepaper
